import { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import FormCostiCorso from '../../../components/FormCostiCorso';
import TitlePage from '../../../components/TitlePage';
import { CourseStatus } from '../../../interfaces/commons';
import { CourseAttributes, CourseRequest } from '../../../interfaces/courses';
import { useAuth } from '../../../contexts/Auth';
import {
  getEntityBalanceTotals,
  isCourseBalanceEnabledForEdit,
} from '../../../libs/utils/helpers';
import { ParticipantStatus } from '../../../interfaces/participants';
import {
  getEstimatedHeadquarterIncome,
  getEstimatedHeadquarterOutflow,
  getEstimatedIncome,
  getEstimatedOutflow,
  getFinalHeadquarterIncome,
  getFinalHeadquarterOutflow,
  getFinalIncome,
  getFinalOutflow,
} from '../../../libs/utils/balancesAggretator';

interface Props {
  defaultCourse: CourseAttributes | undefined;
  title: string | undefined;
}

const CostiCorso: React.FC<Props> = ({ title, defaultCourse }) => {
  const { watch, setValue, getValues } = useFormContext<CourseRequest>();
  const [{ profile }] = useAuth();

  const lessonsNumber = watch('lessons')?.length || 0;

  const partecipants = watch('partecipations');

  const minParticipants = watch('minParticipants');
  const totalConfirmedParticipants = useMemo(
    () =>
      watch('partecipations')?.data.filter(
        (elem) => elem.attributes.status === ParticipantStatus.CONFIRMED
      ).length,
    [watch('partecipations')]
  );

  const updateFoodVoice = () => {
    const food = getValues('balance.food');
    const updatedEstimatedOutflow = food?.estimatedPriceUnit * minParticipants;
    setValue('balance.food.estimatedOutflow', updatedEstimatedOutflow);

    const confirmedParticipants = partecipants?.data?.filter(
      (_participant) =>
        _participant.attributes.status === ParticipantStatus.CONFIRMED
    ).length;

    if (!confirmedParticipants) return;

    const updatedFinalPriceUnit = food?.finalOutflow / confirmedParticipants;
    setValue('balance.food.finalPriceUnit', updatedFinalPriceUnit);
  };

  const updatePriceVoice = () => {
    const minParticipants = watch('minParticipants');
    const subscriptionAmount = watch('subscriptionAmount');

    const estimatedIncome = minParticipants * subscriptionAmount;

    setValue('balance.price.estimatedPriceUnit', subscriptionAmount);
    setValue('balance.price.estimatedIncome', estimatedIncome);
  };

  const updateRoomsVoice = () => {
    const rooms = getValues('balance.rooms');

    const updatedEstimatedPriceUnit = lessonsNumber
      ? rooms?.estimatedOutflow / lessonsNumber
      : 0;
    setValue('balance.rooms.estimatedPriceUnit', updatedEstimatedPriceUnit);

    const updatedFinalPriceUnit = lessonsNumber
      ? rooms?.finalOutflow / lessonsNumber
      : 0;
    setValue('balance.rooms.finalPriceUnit', updatedFinalPriceUnit);
  };

  const updateExtraVoice = () => {
    const estimatedExtraOutflow =
      getEstimatedOutflow(watch('balance'), ['extras']) * 0.1;

    setValue('balance.extras.estimatedOutflow', estimatedExtraOutflow);
  };

  const updateDirectionVoice = () => {
    const supervisorAmount = watch('supervisorAmount') || 0;
    const treasurerAmount = watch('treasurerAmount') || 0;

    const totDirection = supervisorAmount + treasurerAmount;

    setValue('balance.direction.estimatedOutflow', totDirection);
  };

  const updateTeachingMaterialVoice = () => {
    const teachingMaterial = getValues('headquarterBalance.teachingMaterial');

    const estimatedOutflow =
      (teachingMaterial?.estimatedPriceUnit || 0) * minParticipants;

    const defaultTeachingMaterialsBalance =
      defaultCourse?.headquarterBalance?.teachingMaterial;

    /**
     * SOLO IN MODIFICA
     *
     * Se l'utente modifica il valore delle uscite della quota material didattico,
     * allora bisogna segnarlo attraverso l'attributo isTouched
     *
     * Questo servirà poi quando si sincronizza il bilancio.
     *
     * Se è stato toccato significa che non verrà più calcolato automaticamente.
     */

    if (defaultTeachingMaterialsBalance) {
      const { isTouched, finalOutflow: defaultFinalOutflow } =
        defaultTeachingMaterialsBalance;

      if (!isTouched && defaultFinalOutflow !== teachingMaterial.finalOutflow) {
        setValue('headquarterBalance.teachingMaterial.isTouched', true);
      } else if (!isTouched) {
        setValue('headquarterBalance.teachingMaterial.isTouched', false);
      }
    }

    setValue(
      'headquarterBalance.teachingMaterial.estimatedOutflow',
      estimatedOutflow
    );

    if (partecipants?.data.length) {
      const finalPriceUnit = totalConfirmedParticipants
        ? teachingMaterial.finalOutflow / totalConfirmedParticipants
        : 0;
      setValue(
        'headquarterBalance.teachingMaterial.finalPriceUnit',
        finalPriceUnit
      );
    }
  };

  const updateShippingVoice = () => {
    const shipping = getValues('headquarterBalance.shipping');

    const estimatedPriceUnit =
      (shipping?.estimatedOutflow || 0) / (minParticipants || 0);

    setValue(
      'headquarterBalance.shipping.estimatedPriceUnit',
      estimatedPriceUnit
    );

    if (partecipants?.data.length) {
      const finalPriceUnit = totalConfirmedParticipants
        ? shipping.finalOutflow / partecipants?.data.length
        : 0;
      setValue('headquarterBalance.shipping.finalPriceUnit', finalPriceUnit);
    }
  };

  const updateAdministrativeVoice = () => {
    const minParticipants = watch('minParticipants');
    const administrations = watch('headquarterBalance.administrations');
    const defaultAdministrationsBalance =
      defaultCourse?.headquarterBalance.administrations;

    /**
     * SOLO IN MODIFICA
     *
     * Se l'utente modifica il valore delle entrate della quota amministrativa,
     * allora bisogna segnarlo attraverso l'attributo isTouched
     *
     * Questo servirà poi quando si sincronizza il bilancio.
     *
     * Se è stato toccato significa che non verrà più calcolato automaticamente.
     */

    if (defaultAdministrationsBalance) {
      const { isTouched, finalIncome: defaultFinalIncome } =
        defaultAdministrationsBalance;

      if (!isTouched && defaultFinalIncome !== administrations.finalIncome) {
        setValue('headquarterBalance.administrations.isTouched', true);
      } else if (!isTouched) {
        setValue('headquarterBalance.administrations.isTouched', false);
      }
    }

    const amount = getValues(
      'headquarterBalance.administrations.estimatedPriceUnit'
    );

    const estimatedIncome = amount * minParticipants;

    setValue(
      'headquarterBalance.administrations.estimatedIncome',
      estimatedIncome
    );
  };

  const updateSafetyMarginVoice = () => {
    const headquarterBalance = watch('headquarterBalance');
    const balance = watch('balance');
    const safetyMarginCoefficient = watch('safetyMargin');
    const { estimatedOutflow: balanceEstimatedOutflow } =
      getEntityBalanceTotals(
        { ...(balance || {}), ...(headquarterBalance || {}) },
        ['safetyMargin']
      );

    const estimatedOutflow =
      balanceEstimatedOutflow * Number(safetyMarginCoefficient || 0);

    setValue(
      'headquarterBalance.safetyMargin.estimatedOutflow',
      estimatedOutflow
    );
  };

  const watchedValues = watch([
    'minParticipants',
    'bottlePrice',
    'glassesForBottle',
    'wineTypes',
    'subscriptionAmount',
    'partecipations',
    'treasurerAmount',
    'supervisorAmount',
    'orders.payment',

    'balance.food.estimatedPriceUnit',
    'balance.food.finalOutflow',

    'balance.rooms.estimatedOutflow',
    'balance.rooms.finalOutflow',

    'balance.contributes.estimatedIncome',
    'balance.contributes.finalIncome',
    'balance.discountExpenses.estimatedOutflow',
    'balance.couponsExpenses.estimatedOutflow',
    'balance.sponsorship.estimatedOutflow',
    'balance.sponsorship.finalOutflow',

    'balance.extras.finalOutflow',

    'headquarterBalance.teachingMaterial.estimatedPriceUnit',
    'headquarterBalance.teachingMaterial.finalOutflow',

    'headquarterBalance.administrations.estimatedPriceUnit',
    'headquarterBalance.administrations.finalIncome',

    'headquarterBalance.shipping.estimatedOutflow',
    'headquarterBalance.shipping.finalOutflow',

    'balance.wines.estimatedOutflow',
    'balance.wines.finalOutflow',
  ]);

  const disableCostsForm = useMemo(
    () =>
      ![CourseStatus.DRAFT, CourseStatus.PUBLISHED, null, undefined].includes(
        watch('status')
      ),
    [watch('status')]
  );

  const updateWinesVoice = () => {
    const bottlePrice = watch('bottlePrice');
    const minParticipants = watch('minParticipants');
    const glassesForBottle = watch('glassesForBottle');
    const wineTypes = watch('wineTypes');
    const finalOutflow = watch('balance.wines.finalOutflow');

    const confirmedParticipants = partecipants?.data?.filter(
      (_participant) =>
        _participant.attributes.status === ParticipantStatus.CONFIRMED
    ).length;

    setValue(
      'balance.wines.estimatedOutflow',
      Math.ceil(minParticipants / (glassesForBottle || 1)) * wineTypes * bottlePrice
    );

    if (!confirmedParticipants) return;

    setValue(
      'balance.wines.finalPriceUnit',
      finalOutflow / confirmedParticipants
    );
  };

  const disableEditConsuntivo = useMemo(
    () => !isCourseBalanceEnabledForEdit(watch('status'), profile),
    [watch('status'), profile]
  );

  useEffect(() => {
    updateWinesVoice();
    updateFoodVoice();
    updatePriceVoice();
    updateRoomsVoice();
    updateExtraVoice();
    updateDirectionVoice();
    updateTeachingMaterialVoice();
    updateShippingVoice();
    updateSafetyMarginVoice();
    updateAdministrativeVoice();
  }, [partecipants, ...watchedValues, defaultCourse]);

  return (
    <>
      <TitlePage title={`${title} | Costi`} />
      <FormCostiCorso
        estimatedIncome={getEstimatedIncome(watch('balance'))}
        estimatedOutflow={getEstimatedOutflow(watch('balance'))}
        finalIncome={getFinalIncome(watch('balance'))}
        finalOutflow={getFinalOutflow(watch('balance'))}
        estimatedHeadquarterOutflow={getEstimatedHeadquarterOutflow(
          watch('headquarterBalance')
        )}
        estimatedHeadquarterIncome={getEstimatedHeadquarterIncome(
          watch('headquarterBalance')
        )}
        finalHeadquarterOutflow={getFinalHeadquarterOutflow(
          watch('headquarterBalance')
        )}
        finalHeadquarterIncome={getFinalHeadquarterIncome(
          watch('headquarterBalance')
        )}
        disabled={disableCostsForm}
        courseStatus={watch('status')}
        safetyMargin={watch('safetyMargin')}
        disableEditConsuntivo={disableEditConsuntivo}
      />
    </>
  );
};

export default CostiCorso;
