import moment from 'moment';
import { ReactNode, useMemo } from 'react';
import { useQuery } from 'react-query';
import appSettings from '../../api/appSettings';
import statisticsAPIs from '../../api/statisticsAPIs';
import { useAuth } from '../../contexts/Auth';
import { Statistics } from '../../interfaces/statistics';

export function useStatisticsYears(): [number, number, number] {
  const [{ token }] = useAuth();

  const appSettingsQuery = useQuery(['appSettings'], () =>
    appSettings.find({ token })
  );

  const showNextYear = useMemo(() => {
    const [day, month] =
      appSettingsQuery.data?.data?.displayNextSubscriptionDayMonth?.split(
        '/'
      ) || [moment().day(), moment().month()];
    return moment(`${month}/${day}/${new Date().getFullYear()}`).isSameOrBefore(
      moment().toISOString()
    );
  }, [appSettingsQuery.data]);

  const currentYear = new Date().getFullYear();
  const last = showNextYear ? currentYear + 1 : currentYear;

  const years = [last, last - 1, last - 2] as [number, number, number];
  return years;
}

function calculateChange(
  a: number,
  b: number
): {
  absolute: ReactNode;
  percentage: ReactNode;
} {
  const difference = a - b;
  const percentageChange = ((a - b) / b) * 100;
  const percentage =
    b === 0
      ? '-'
      : (percentageChange >= 0 ? '+' : '') + percentageChange.toFixed(1) + '%';
  return {
    absolute: difference.toFixed(0),
    percentage,
  };
}

function calculateComparison(years: [Statistics, Statistics, Statistics]) {
  return {
    subscriptions: {
      total: {
        prevYear: years[2].subscriptionsReports.totals,
        currentYear: years[1].subscriptionsReports.totals,
        nextYear: years[0].subscriptionsReports.totals,
        nextOrCurrentOnPrevChange: calculateChange(
          years[0].subscriptionsReports.totals,
          years[2].subscriptionsReports.totals
        ),
        nextOnCurrentChange: calculateChange(
          years[0].subscriptionsReports.totals,
          years[1].subscriptionsReports.totals
        ),
      },
      juniors: {
        prevYear: years[2].subscriptionsReports.juniors,
        currentYear: years[1].subscriptionsReports.juniors,
        nextYear: years[0].subscriptionsReports.juniors,
        nextOrCurrentOnPrevChange: calculateChange(
          years[0].subscriptionsReports.juniors,
          years[2].subscriptionsReports.juniors
        ),
        nextOnCurrentChange: calculateChange(
          years[0].subscriptionsReports.juniors,
          years[1].subscriptionsReports.juniors
        ),
      },
      renewals: {
        prevYear: years[2].subscriptionsReports.renewals,
        currentYear: years[1].subscriptionsReports.renewals,
        nextYear: years[0].subscriptionsReports.renewals,
        nextOrCurrentOnPrevChange: calculateChange(
          years[0].subscriptionsReports.renewals,
          years[2].subscriptionsReports.renewals
        ),
        nextOnCurrentChange: calculateChange(
          years[0].subscriptionsReports.renewals,
          years[1].subscriptionsReports.renewals
        ),
      },
      reenrollments: {
        prevYear: years[2].subscriptionsReports.reenrollments,
        currentYear: years[1].subscriptionsReports.reenrollments,
        nextYear: years[0].subscriptionsReports.reenrollments,
        nextOrCurrentOnPrevChange: calculateChange(
          years[0].subscriptionsReports.reenrollments,
          years[2].subscriptionsReports.reenrollments
        ),
        nextOnCurrentChange: calculateChange(
          years[0].subscriptionsReports.reenrollments,
          years[1].subscriptionsReports.reenrollments
        ),
      },
      newSubs: {
        prevYear: years[2].subscriptionsReports.newSubs,
        currentYear: years[1].subscriptionsReports.newSubs,
        nextYear: years[0].subscriptionsReports.newSubs,
        nextOrCurrentOnPrevChange: calculateChange(
          years[0].subscriptionsReports.newSubs,
          years[2].subscriptionsReports.newSubs
        ),
        nextOnCurrentChange: calculateChange(
          years[0].subscriptionsReports.newSubs,
          years[1].subscriptionsReports.newSubs
        ),
      },
    },
  };
}

export function useComparisonStatistics({
  date: propsDate,
  sectionId,
}: {
  date?: string;
  sectionId?: string;
}) {
  const [{ token }] = useAuth();
  const years = useStatisticsYears();

  const date = moment(propsDate).endOf('day').toISOString();
  return useQuery(
    ['comparisonStatistics', years, date, sectionId],
    async () => {
      const stats = await Promise.all(
        years.map((year) =>
          statisticsAPIs.calculate({
            token,
            query: {
              year,
              date: moment(date).toISOString(),
              sectionId,
            },
          })
        )
      );

      return calculateComparison(stats as [Statistics, Statistics, Statistics]);
    }
  );
}
